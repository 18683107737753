import { useEffect } from "react";
import Header from "../../components/header/Header";
import { useNavigate, useParams } from "react-router-dom";
import "./home.css";
import { IoHome } from "react-icons/io5";

const Home = () => {
  const { name } = useParams();
  const cardInfo = [
    {
      id: "1",
      name: "Prepaid",
      simType: "PREPAID",
      img: require("../../assets/images/prepaid.png"),
      navigateLink: `/image-audit/${name}/PREPAID`,
    },
    {
      id: "2",
      name: "Postpaid",
      simType: "POSTPAID",
      img: require("../../assets/images/postpaid.png"),
      navigateLink: `/image-audit/${name}/POSTPAID`,
    },
    {
      id: "3",
      name: "DCAF",
      simType: "DCAF",
      img: require("../../assets/images/dcaf.png"),
      navigateLink: `/verification/${name}/DCAF`,
    },
    {
      id: "4",
      name: "eKYC",
      simType: "EKYC",
      img: require("../../assets/images/ekyc.png"),
      navigateLink: `/verification/${name}/EKYC`,
    },
    {
      id: "4",
      name: "Paper CAF",
      simType: "PAPER CAF",
      img: require("../../assets/images/dcaf.png"),
      navigateLink: `/paper-audit/${name}/PAPER`,
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("info") === null) {
      navigate("/login");
    }
  }, []);

  const handleImageAuditPage = (navigateLink) => {
    navigate(navigateLink);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      <section className="home-main">
        <div className="homePageBackBtn" onClick={handleBack}>
          <IoHome />
        </div>
        <div className="container">
          <div className="row">
            {cardInfo.map((ele) => {
              return (
                <div key={ele.id} className="col-lg-3 homecard">
                  <div
                    className="dashboardCard"
                    onClick={() => handleImageAuditPage(ele.navigateLink)}
                  >
                    <div className="dashboardIconBox">
                      <img src={ele.img} alt={ele.name} className="img-fluid" />
                    </div>
                    <p>{ele.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
