import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../header/Header";
import loginLeft from "../../assets/images/adminLoginBg.png";
import logo from "../../assets/images/logo.png";
import Footer from "../footer/Footer";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import "../login/login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import { Link, useNavigate } from "react-router-dom";

const QcPanelLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("qcInfo") !== null) {
      navigate("/qc-panel");
    }
  }, []);

  const [passwordToggle, setPasswordToggle] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formData;

    try {
      const finalData = {
        user_id: email,
        password: password,
        // session_id: localStorage.getItem("session_id"),
      };
      // console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "auditor/qc_login",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("response ", response.data);

      if (response.data.status) {
        toast.success(response.data.message);
        sessionStorage.setItem("qcInfo", JSON.stringify(response.data.data));
        setTimeout(() => {
          navigate("/qc-panel");
        }, 1000);
        // const sessionId = response.data.session_id;
        // console.log(sessionId);
        // sessionStorage.setItem("session_id", sessionId);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data.error);
    }
  };

  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };

  return (
    <>
      <Header />
      <section className="login-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 d-flex justify-content-center align-align-items-center ">
              <div className="login-left">
                <img src={logo} alt="login-bg" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-5 d-flex justify-content-center align-align-items-center">
              <div className="login-right">
                <div className="login-r-logo-box">
                  <img src={logo} alt="logo" className="img-fluid" />
                  {/* <p className="lLogoBoxP1">Vodafone Idea limited </p> */}
                  <p className="lLogoBoxP2">Dynamic Information Technology</p>
                  <h6 className="lLogoBoxTitle">Quality Control</h6>
                </div>
                <form id="adminForm" name="adminForm">
                  <div className="LoginInputBox">
                    <div className="lInputIcoGroup">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control loginCommonInput"
                        placeholder="User Id"
                        autoComplete
                      />
                      <div className="loginInputIcon">
                        <svg
                          width={23}
                          height={23}
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_1_21"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x={0}
                            y={0}
                            width={24}
                            height={24}
                          >
                            <rect width={24} height={24} fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_1_21)">
                            <path
                              d="M11.5 11.2051C10.5776 11.2051 9.78798 10.8767 9.13112 10.2198C8.47426 9.56297 8.14583 8.77335 8.14583 7.85097C8.14583 6.92857 8.47426 6.13895 9.13112 5.48211C9.78798 4.82526 10.5776 4.49683 11.5 4.49683C12.4224 4.49683 13.212 4.82526 13.8688 5.48211C14.5257 6.13895 14.8541 6.92857 14.8541 7.85097C14.8541 8.77335 14.5257 9.56297 13.8688 10.2198C13.212 10.8767 12.4224 11.2051 11.5 11.2051ZM4.3125 18.5032V16.3727C4.3125 15.9034 4.43997 15.4688 4.69492 15.0689C4.94986 14.669 5.29049 14.3615 5.71682 14.1465C6.6641 13.6821 7.61967 13.3337 8.58353 13.1015C9.54739 12.8693 10.5195 12.7532 11.5 12.7532C12.4804 12.7532 13.4526 12.8693 14.4164 13.1015C15.3803 13.3337 16.3359 13.6821 17.2831 14.1465C17.7095 14.3615 18.0501 14.669 18.305 15.0689C18.56 15.4688 18.6875 15.9034 18.6875 16.3727V18.5032H4.3125ZM5.74998 17.0657H17.25V16.3727C17.25 16.1786 17.1938 15.9989 17.0813 15.8337C16.9689 15.6684 16.8163 15.5336 16.6234 15.4291C15.7977 15.0225 14.9559 14.7144 14.0977 14.5049C13.2396 14.2954 12.3737 14.1907 11.5 14.1907C10.6263 14.1907 9.76034 14.2954 8.90222 14.5049C8.0441 14.7144 7.20222 15.0225 6.37658 15.4291C6.18369 15.5336 6.03103 15.6684 5.91862 15.8337C5.80619 15.9989 5.74998 16.1786 5.74998 16.3727V17.0657ZM11.5 9.76764C12.0271 9.76764 12.4783 9.57996 12.8536 9.20462C13.229 8.82927 13.4166 8.37805 13.4166 7.85097C13.4166 7.32389 13.229 6.87267 12.8536 6.49732C12.4783 6.12198 12.0271 5.9343 11.5 5.9343C10.9729 5.9343 10.5217 6.12198 10.1463 6.49732C9.77098 6.87267 9.58331 7.32389 9.58331 7.85097C9.58331 8.37805 9.77098 8.82927 10.1463 9.20462C10.5217 9.57996 10.9729 9.76764 11.5 9.76764Z"
                              fill="#626262"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="LoginInputBox">
                    <div className="lInputIcoGroup">
                      <input
                        name="password"
                        type={!passwordToggle ? "password" : "text"}
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control loginCommonInput"
                        placeholder="Password"
                        autoComplete
                      />
                      <div className="loginInputIcon">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_1_28"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                          >
                            <rect width={20} height={20} fill="#626262" />
                          </mask>
                          <g mask="url(#mask0_1_28)">
                            <path
                              d="M5.25642 7.08334H12.5V5.41668C12.5 4.72223 12.2569 4.13195 11.7708 3.64584C11.2847 3.15973 10.6944 2.91668 9.99998 2.91668C9.30553 2.91668 8.71526 3.15973 8.22915 3.64584C7.74303 4.13195 7.49998 4.72223 7.49998 5.41668H6.25C6.25 4.37609 6.61485 3.49095 7.34454 2.76126C8.07425 2.03157 8.9594 1.66672 9.99998 1.66672C11.0406 1.66672 11.9257 2.03157 12.6554 2.76126C13.3851 3.49095 13.75 4.37609 13.75 5.41668V7.08334H14.7435C15.1591 7.08334 15.5141 7.23052 15.8084 7.52486C16.1028 7.8192 16.25 8.17416 16.25 8.58976V16.4102C16.25 16.8258 16.1028 17.1808 15.8084 17.4751C15.5141 17.7695 15.1591 17.9166 14.7435 17.9166H5.25642C4.84082 17.9166 4.48585 17.7695 4.19152 17.4751C3.89717 17.1808 3.75 16.8258 3.75 16.4102V8.58976C3.75 8.17416 3.89717 7.8192 4.19152 7.52486C4.48585 7.23052 4.84082 7.08334 5.25642 7.08334ZM5.25642 16.6667H14.7435C14.8183 16.6667 14.8798 16.6426 14.9279 16.5945C14.9759 16.5464 15 16.485 15 16.4102V8.58976C15 8.51497 14.9759 8.45353 14.9279 8.40545C14.8798 8.35736 14.8183 8.33332 14.7435 8.33332H5.25642C5.18163 8.33332 5.12019 8.35736 5.0721 8.40545C5.02402 8.45353 4.99998 8.51497 4.99998 8.58976V16.4102C4.99998 16.485 5.02402 16.5464 5.0721 16.5945C5.12019 16.6426 5.18163 16.6667 5.25642 16.6667ZM9.99998 13.9583C10.4049 13.9583 10.7492 13.8165 11.0328 13.5328C11.3165 13.2492 11.4583 12.9049 11.4583 12.5C11.4583 12.0951 11.3165 11.7508 11.0328 11.4672C10.7492 11.1835 10.4049 11.0417 9.99998 11.0417C9.59506 11.0417 9.25078 11.1835 8.96715 11.4672C8.68349 11.7508 8.54167 12.0951 8.54167 12.5C8.54167 12.9049 8.68349 13.2492 8.96715 13.5328C9.25078 13.8165 9.59506 13.9583 9.99998 13.9583Z"
                              fill="#626262"
                            />
                          </g>
                        </svg>
                      </div>
                      <div className="passwordIconBox">
                        {!passwordToggle ? (
                          <IoEyeOffOutline onClick={handlePasswordToggle} />
                        ) : (
                          <IoEyeOutline onClick={handlePasswordToggle} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="loginCheckboxSec">
                    <input type="checkbox" className="" />
                    <span>Remember Me</span>
                  </div>
                  <div className="loginBtnBox">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btn loginBtn1"
                    >
                      Login
                    </button>
                    <Link to="/login" className="btn loginBtn2">
                      Auditor Login
                    </Link>
                    <Link to="/admin/login" className="btn loginBtn2">
                      Admin Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
      <Footer />
    </>
  );
};

export default QcPanelLogin;
