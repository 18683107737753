import { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import "./panel.css";
import DataTable from "react-data-table-component";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { FaUserEdit } from "react-icons/fa";

const QcPanelHome = () => {
  const [qcPanelList, setQcPanelList] = useState([]);
  const [search, setSearch] = useState("");
  const [simType, setSimType] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("qcInfo") === null) {
      navigate("/qc-panel/login");
    }
  }, []);

  const handleEdit = (id) => {
    navigate(`/qc-panel/${id}`);
  };

  useEffect(() => {
    handleAuditorList();
  }, [simType]);

  const handleAuditorList = async () => {
    try {
      const data = {
        sim_type: simType,
      };
      const response = await axios.post(
        API_BASE_URL + "auditor/audited_customer_list",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("qc list response", response.data);
      setQcPanelList(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  function formatDate(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Get the individual date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note: January is 0
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`;

    return formattedDate;
  }

  // Example usage
  const inputDate = "2024-04-14 12:41:38";
  const formattedDate = formatDate(inputDate);
  // console.log(formattedDate);

  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => index + 1,
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => <div>{row.name_of_the_subscriber}</div>,
      // sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => <div>{row.telephone_number}</div>,
      // sortable: true,
    },
    {
      name: "Type",
      selector: (row) => <div>{row.address_type}</div>,
      // sortable: true,
    },
    {
      name: "Act.Dt",
      selector: (row) => <div>{row.sim_activation_date}</div>,
      // sortable: true,
    },
    {
      name: "CAF No",
      selector: (row) => <div>{row.caf_serial_no}</div>,
      // sortable: true,
    },
    // {
    //   name: "Address",
    //   selector: (row) => <div>{row.mobile}</div>,
    //   // sortable: true,
    // },
    {
      name: "Audit By",
      selector: (row) => <div>{row.opened_by}</div>,
      // sortable: true,
    },
    {
      name: "Audit At",
      selector: (row) => <div>{formatDate(row.audited_at)}</div>,
      // sortable: true,
    },
    {
      name: "QC Check By",
      selector: (row) => <div>{row.qc_checked_by}</div>,
      // sortable: true,
    },
    {
      name: "City",
      selector: (row) => <div>{row.city}</div>,
      // sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          style={{
            color:
              row.qc_application_status === null ||
              row.qc_application_status === ""
                ? row.application_status === "ACCEPTED"
                  ? "green"
                  : "red"
                : row.qc_application_status === "ACCEPTED"
                ? "green"
                : "red",
          }}
        >
          {row.qc_application_status === null ||
          row.qc_application_status === ""
            ? row.application_status
            : row.qc_application_status}
        </div>
      ),
      // sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="dataTableActionTab">
          <div
            className="dataTableActionTabBox"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="edit"
            onClick={() => handleEdit(row.id)}
          >
            <FaUserEdit />
          </div>
        </div>
      ),
    },
  ];

  const customStyles = {
    table: {
      style: {
        border: "1px solid #d9d9d9",
        fontFamily: "Poppins",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },

    headRow: {
      style: {
        border: "none", // Set border style for the header row
        fontFamily: "Poppins",
        fontWeight: "500",
        color: "#000",
        backgroundColor: "#d9d9d9",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontFamily: "Poppins",
        fontSize: "13px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const filterCat = qcPanelList.filter((elem) => {
    return elem.name_of_the_subscriber
      .toLowerCase()
      .includes(search.toLowerCase());
  });

  return (
    <>
      <Header />
      <section className="qcMainSec">
        <div className="container-fluid">
          <header className="qcHeading">
            <div className="qcHeading_tab">
              <div>
                <h6 className="">
                  Number of audited subscriber {filterCat.length}{" "}
                </h6>
              </div>
              <div className="select_vale_qc_heading">
                <select
                  value={simType}
                  onChange={(e) => setSimType(e.target.value)}
                  className="form-control exportSelectInput"
                >
                  <option value="">All</option>
                  <option value="PREPAID">Prepaid</option>
                  <option value="POSTPAID">Postpaid</option>
                  <option value="DCAF">DCAF</option>
                  <option value="EKYC">eKYC</option>
                </select>
              </div>
            </div>
          </header>
          <div className="row">
            <div className="col-lg-12">
              <div className="qcPanelListBox">
                {/* <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                /> */}
                <DataTable
                  columns={columns}
                  data={filterCat}
                  pagination
                  paginationPerPage={10}
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QcPanelHome;
