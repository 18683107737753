import Header from "../../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import SideBar from "../../components/sideBar/SideBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./remark.css";

const AddRemark = () => {
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);
  const [status, setStatus] = useState(false);
  const [formData, setFormData] = useState({
    userId: "",
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const userDetails = sessionStorage.getItem("adminInfo");
  const userInfo = JSON.parse(userDetails);

  useEffect(() => {
    if (sessionStorage.getItem("adminInfo") === null) {
      navigate("/admin/login");
    }
  }, []);
  // categories api

  const handleAddAuditor = async (event) => {
    setIsAdding(true);
    event.preventDefault();
    const { name } = formData;
    try {
      const finalData = {
        name: name,
        status: status ? "Y" : "N",
      };
      // console.log("add remark finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "admin/add_remark",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      // console.log("add remark response", response.data);
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/admin/manage-remark");
        }, 2000);
      } else {
        toast.error(response.data.message);
        setIsAdding(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Header />
      <section className="auditorMain">
        <SideBar />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="auditorDataTableBox">
                <div className="auditorTitleBox">
                  <h5>Add Remark</h5>
                </div>
                <div className="auditorMainFormSec">
                  <form
                    className="addAuditorForm"
                    name="addAuditor"
                    id="addAuditor"
                    onSubmit={handleAddAuditor}
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="auditorGroup">
                          <label htmlFor="">Name :-</label>
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="auditorGroup">
                          <label htmlFor="">Status :-</label>
                          <input
                            type="checkbox"
                            checked={status}
                            onChange={handleStatusChange}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="auditorBtnGroup">
                          {!isAdding ? (
                            <button className="btn">Add</button>
                          ) : (
                            <button disabled className="btn">
                              Adding
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      </section>
    </>
  );
};

export default AddRemark;
