import Header from "../../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import SideBar from "../../components/sideBar/SideBar";
import DataTable from "react-data-table-component";
import "../manageAuditor/auditor.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QcList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [auditorData, setAuditorData] = useState([]);

  const userDetails = sessionStorage.getItem("adminInfo");
  const userInfo = JSON.parse(userDetails);

  useEffect(() => {
    if (sessionStorage.getItem("adminInfo") === null) {
      navigate("/admin/login");
    }
  }, []);

  // qc list api

  useEffect(() => {
    handleAuditorList();
  }, []);

  const handleAuditorList = async () => {
    try {
      const response = await axios.get(API_BASE_URL + "admin/qc_list", {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      // console.log("qc list response", response.data);
      setIsLoading(false);
      setAuditorData(response.data.data);
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const handleRefresh = async () => {
    await handleAuditorList();
  };

  const filterCat = auditorData.filter((elem) => {
    return elem.name.toLowerCase().includes(search.toLowerCase());
  });

  const handleEdit = (id) => {
    navigate(`/admin/edit-qc/${id}`);
  };

  // delete qc

  const handleDelete = async (id) => {
    var check = window.confirm("Are Your Sure To Delete This Qc ?");
    if (!check) {
      return;
    }
    const finalData = {
      qc_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/delete_qc",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("delete qc", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const columns = [
    {
      name: "Sr.No",
      selector: (row, index) => index + 1,
      // sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => <div>{row.user_id}</div>,
      // sortable: true,
    },
    {
      name: "Name",
      selector: (row) => <div>{row.name}</div>,
      // sortable: true,
    },
    {
      name: "Email",
      selector: (row) => <div>{row.email}</div>,
      // sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => <div>{row.mobile}</div>,
      // sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.status === "Y" ? (
            <span className="active_status text-success">Active</span>
          ) : (
            <span className="dective_status text-danger">Deactive</span>
          )}
        </>
      ),
      // sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="dataTableActionTab">
          <div
            className="dataTableActionTabBox"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="edit"
            onClick={() => handleEdit(row.id)}
          >
            <FaUserEdit />
          </div>
          <div
            className="dataTableActionTabBox"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Cancel"
            onClick={() => handleDelete(row.id)}
          >
            <MdDelete className="deleteIcon" />
          </div>
        </div>
      ),
    },
  ];

  const customStyles = {
    table: {
      style: {
        border: "1px solid #d9d9d9",
        fontFamily: "Poppins",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },

    headRow: {
      style: {
        border: "none", // Set border style for the header row
        fontFamily: "Poppins",
        fontWeight: "500",
        color: "#000",
        backgroundColor: "#d9d9d9",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        fontFamily: "Poppins",
        fontSize: "13px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const handleAddAuditor = () => {
    navigate("/admin/add-qc");
  };

  return (
    <>
      <Header />
      <section className="auditorMain">
        <SideBar />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="auditorDataTableBox">
                <div className="auditorTitleBox">
                  <h5>QC User List</h5>
                  <button className="btn" onClick={handleAddAuditor}>
                    Add
                  </button>
                </div>
                <DataTable
                  columns={columns}
                  data={filterCat}
                  pagination
                  paginationPerPage={10}
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </section>
    </>
  );
};

export default QcList;
