import Header from "../../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import "../adminHome/adminHome.css";
import { useEffect, useState } from "react";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import SideBar from "../../components/sideBar/SideBar";

const AdminExportFile = () => {
  const navigate = useNavigate();

  // const [base64Data, setBase64Data] = useState("");
  // const [uploadFileData, setUploadFileData] = useState("");
  // const [downloadSampleFileUrl, setDownloadSampleFileUrl] = useState("");
  const [simType, setSimType] = useState("");
  const userDetails = sessionStorage.getItem("adminInfo");
  const userInfo = JSON.parse(userDetails);

  useEffect(() => {
    if (sessionStorage.getItem("adminInfo") === null) {
      navigate("/admin/login");
    }
  }, []);

  // download sample file

  // const handleDownloadSampleFile = async () => {
  //   try {
  //     const finalData = {};
  //     const response = await axios.post(
  //       API_BASE_URL + "admin/excel_sample_file",
  //       finalData,
  //       {
  //         auth: {
  //           username: API_AUTH_USERNAME,
  //           password: API_AUTH_PASSWORD,
  //         },
  //       }
  //     );

  //     console.log("download sample file response", response.data.data);
  //     setDownloadSampleFileUrl(response.data.data);
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response.data.data);
  //       console.log(error.response.status);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   handleDownloadSampleFile();
  // }, []);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       const base64Content = e.target.result.split(",")[1];
  //       setBase64Data(base64Content);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  // upload file api

  const handleUploadData = async (e) => {
    e.preventDefault();
    if (simType === "") {
      alert("Sim Type Is Require !");
      return;
    }
    try {
      const data = {
        user_id: userInfo.id,
        sim_type: simType,
      };
      // console.log("data", data);
      const response = await axios.post(
        API_BASE_URL + "admin/export_data",
        data,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("export file response", response);
      if (response.data.status && response.data.data !== null) {
        const url = response.data.data;
        window.open(url, "_blank");
      }
      alert(response.data.message);
    } catch (error) {
      console.log("error occurred in add imp places", error);
    }
  };

  return (
    <>
      <Header />
      <section className="adminHomeMain">
        <SideBar />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="downloadSamplePdfFileBox">
                <header className="headerAdmin">
                  <h5>Export File</h5>
                </header>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="pdfUploadMain">
                <form
                  id="exportForm"
                  name="exportForm"
                  className="pdfUploadForm"
                >
                  {/* <input
                    type="file"
                    accept=".xls, .xlsx"
                    className="form-control"
                    onChange={handleFileChange}
                  /> */}
                  <select
                    value={simType}
                    onChange={(e) => setSimType(e.target.value)}
                    className="form-control exportSelectInput"
                  >
                    <option value="">Select</option>
                    <option value="ALL">All</option>
                    <option value="PREPAID">Prepaid</option>
                    <option value="POSTPAID">Postpaid</option>
                    <option value="DCAF">DCAF</option>
                    <option value="EKYC">eKYC</option>
                    <option value="PAPER">PAPER</option>
                  </select>
                  <button className="btn w-50" onClick={handleUploadData}>
                    Export Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminExportFile;
