/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import "./detailPage.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import AuditModal from "../../components/auditModal/AuditModal";
import Loader from "../../components/loader/Loader";
import { LuRotateCw } from "react-icons/lu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoHome } from "react-icons/io5";

pdfjs.GlobalWorkerOptions.workerSrc =
  "path-to-pdfjs/2.9.359/build/pdf.worker.min.js";

const DetailPage = () => {
  const remarkRefs = useRef([]);
  const { name, simType } = useParams();
  const [cafNo, setCafNo] = useState("");
  // const [pdfLoaded, setPdfLoaded] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [auditMessagePopUp, setAuditMessagePopUp] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [remarkChecked, setRemarkChecked] = useState({});
  const [customerInfo, setCustomerInfo] = useState([]);
  const [color, setColor] = useState(false);
  const [rv, setRv] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState("");
  const [otherRemark, setOtherRemark] = useState("");
  const [showBtn, setShowBtn] = useState(true);

  useEffect(() => {
    if (sessionStorage.getItem("info") === null) {
      navigate("/login");
    }
  }, []);
  const handleColorChange = () => {
    setColor(!color);
  };

  const handleRvChange = () => {
    setRv(!rv);
  };

  const userDetails = sessionStorage.getItem("info");
  const userInfo = JSON.parse(userDetails);

  const handleRemarkCheckboxToggle = (label) => {
    setRemarkChecked({
      ...remarkChecked,
      [label]: !remarkChecked[label],
    });
  };

  // handle keys

  const handleKeyDown = (event, index) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = (index + 1) % remarkRefs.current.length;
      remarkRefs.current[nextIndex].focus();
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleRemarkCheckboxToggle(remarkList[index].name);
    } else if (event.key === " ") {
      event.preventDefault();
      handleRemarkCheckboxToggle(remarkList[index].name);
    }
  };

  const handleKeyUp = (event, index) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      const prevIndex = index === 0 ? remarkRefs.current.length - 1 : index - 1;
      remarkRefs.current[prevIndex].focus();
    }
    // else if (event.key === "Enter") {
    //   event.preventDefault();
    //   handleRemarkCheckboxToggle(remarkList[index].name);
    // }
  };

  // pass remark value in select remark input

  useEffect(() => {
    const checkedRemarks = Object.entries(remarkChecked)
      .filter(([label, isChecked]) => isChecked)
      .map(([label]) => label);
    setSelectedRemark(checkedRemarks.join(", "));
  }, [remarkChecked]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1.0);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    // Specify the path to the PDF.js worker script
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const [zoomDeclaration, setZoomDeclaration] = useState(1);
  const [zoomId, setZoomId] = useState(1);

  // Maximum and minimum zoom levels
  const maxZoom = 3;
  const minZoom = 1;

  // Zoom handlers for the "Customer Declaration" image
  const handleZoomInDeclaration = () => setZoomDeclaration(prev => Math.min(prev + 0.1, maxZoom));
  const handleZoomOutDeclaration = () => setZoomDeclaration(prev => Math.max(prev - 0.1, minZoom));

  // Zoom handlers for the "Customer ID" image
  const handleZoomInId = () => setZoomId(prev => Math.min(prev + 0.1, maxZoom));
  const handleZoomOutId = () => setZoomId(prev => Math.max(prev - 0.1, minZoom));

  const handleResetZoom = () => {
    setZoom(1.0);
    setPageNumber(1);
    setRotate(0);
  };

  // Rotate page btn
  // customer data api

  const handleCustomerInfo = async () => {
    setIsLoading(true);
    try {
      const finalData = {
        sim_type: simType,
        user_id: userInfo.id,
        city: name,
      };
      console.log("finalData customer info", finalData);

      const response = await axios.post(
        API_BASE_URL + "auditor/get_customer_paper_data",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("response customer info", response.data);

      if (response.data.status === true) {
        setCustomerInfo(response.data.data);
        setCafNo(response.data.data.caf_serial_no);
        setIsLoading(false);
      } else {
        setAuditMessagePopUp(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  useEffect(() => {
    handleCustomerInfo();
  }, [simType]);

  // for customer info data call again
  const reloadPage = () => {
    navigate(0);  // This triggers a reload of the current route
  };
  const handleRefreshCustomerInfo = async () => {
    reloadPage();
    //await handleCustomerInfo();
  };

  // close status api

  const handleOpenedCustomer = async () => {
    try {
      const finalData = {
        user_id: userInfo.id,
        customer_id: customerInfo?.id,
        status: "close",
      };
      // console.log("opened", finalData);
      const response = await axios.post(
        API_BASE_URL + "auditor/update_auditor_customer_form_status",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("response opened customer", response.data);
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  useEffect(() => {
    // window closed then call this
    const handleWindowClose = () => {
      handleOpenedCustomer();
    };

    // browser is closed then call this
    const handleUnload = () => {
      handleOpenedCustomer();
    };

    window.addEventListener("beforeunload", handleWindowClose);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
      window.removeEventListener("unload", handleUnload);
    };
  }, [userInfo?.id, customerInfo?.id]);

  // when back to navigate page then call this

  useEffect(() => {
    return () => {
      // Check if the user navigates from DetailPage to Home
      if (window.location.pathname === "/") {
        handleOpenedCustomer();
      }
    };
  }, [window.location.pathname, userInfo?.id, customerInfo?.id]);

  // update customer data api
  const updateCustomerData = async (status) => {
    // var check = window.confirm(`Are You Sure To ${status} This Data ?`);
    // if (!check) {
    //   return;
    // }
    setIsLoading(true);
    try {
      // remark value
      const checkedRemarks = Object.entries(remarkChecked)
        .filter(([label, isChecked]) => isChecked)
        .map(([label]) => label);

      const finalData = {
        user_id: userInfo.id,
        customer_id: customerInfo?.id,
        color: color ? "YES" : "NO",
        // rv: rv ? "YES" : "NO",
        caf_no: cafNo,
        remark: checkedRemarks,
        selected_remark: selectedRemark,
        other_remark: otherRemark,
        status: status,
      };
      // console.log("finalData update customer ", finalData);
      const response = await axios.post(
        API_BASE_URL + "auditor/update_form_status",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("response update customer", response.data);
      if (response.data.status === true) {
        handleRefreshCustomerInfo();
        toast.success(response.data.message);
        setColor(false);
        setRv(false);
        setSelectedRemark("");
        setOtherRemark("");
        setRemarkChecked([]);
        setIsLoading(false);
        handleResetZoom();
        setShowBtn(false);
        setTimeout(() => {
          setShowBtn(true);
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  // customer data api

  const handleRemarkList = async () => {
    try {
      const response = await axios.get(API_BASE_URL + "auditor/remark_list", {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      // console.log("response remark list", response.data);
      setRemarkList(response.data.data);
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  useEffect(() => {
    handleRemarkList();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header handleOpenedCustomer={handleOpenedCustomer} />
      {/* {isLoading ? (
        <Loader />
      ) : (
       
      )} */}
      <section className="detailMain">
        {isLoading && <Loader />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 ps-0  pe-0 ">
              <div className="imageAuditMain">
                <div className="homeNavigateBox" onClick={handleBack}>
                  <IoHome />
                </div>
                <p>Image Audit</p>
              </div>
            </div>
          </div>
          <div className="row p-2">
            <div className="col-lg-8 imagesAuditCol9 ps-0 pe-0">
              <div className="pdfViewerMain">
                <div className="pdfViewerTop d-flex justify-content-between">

                  {/* Customer Declaration Image */}
                  {!customerInfo?.customer_form_pdf_link ||
                    customerInfo.customer_form_pdf_link === "" ||
                    customerInfo.customer_form_pdf_link === "https://microdynamicsoftware.uk/vi_image_audit/uploads/page_cafe/" ? (
                    <p className="text-danger">No Images Available</p>
                  ) : (
                    <div className="image-container">


                      <p style={{ fontWeight: 500 }}>Customer Declaration</p>

                      <img
                        src={customerInfo.customer_form_pdf_link}
                        alt="Customer Declaration"
                        style={{
                          width: '100%',
                          transform: `scale(${zoomDeclaration})`,
                        }}
                      />
                    </div>
                  )}

                  {/* Customer ID Image */}
                  {!customerInfo?.customer_id_image ||
                    customerInfo.customer_id_image === "" ||
                    customerInfo.customer_id_image === "https://microdynamicsoftware.uk/vi_image_audit/uploads/page_cafe/" ? (
                    <p className="text-danger">No Images Available</p>
                  ) : (
                    <div className="image-container">
                      <p style={{ fontWeight: 500 }}>Customer Id</p>
                      <img
                        src={customerInfo.customer_id_image}
                        alt="Customer Id"
                        style={{
                          width: '75%',
                          transform: `scale(${zoomId})`,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              
              <div className="pdfViewerBottom">
                  
                
                <div className="p_v_b_right">
                  {zoomId < maxZoom && (
                    <button
                    title="Zoom In"
                    onClick={handleZoomInDeclaration}
                    className="btn p_v_b_r_btn"
                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3_348"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={32}
                        height={32}
                      >
                        <rect width={32} height={32} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3_348)">
                        <path
                          d="M26.0565 27.4359L17.6821 19.0615C17.0154 19.6119 16.2488 20.0427 15.3821 20.3538C14.5154 20.6649 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0314 6.90631 18.4533C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46157 6.90571 6.88208C8.4838 5.30261 10.412 4.51288 12.6903 4.51288C14.9686 4.51288 16.8975 5.30212 18.477 6.88061C20.0565 8.4591 20.8462 10.3878 20.8462 12.6667C20.8462 13.6188 20.6864 14.5282 20.3667 15.3949C20.047 16.2615 19.6205 17.0154 19.0872 17.6564L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8205C14.4103 18.8205 15.8655 18.2244 17.0578 17.0321C18.2501 15.8398 18.8462 14.3846 18.8462 12.6667C18.8462 10.9487 18.2501 9.49359 17.0578 8.30128C15.8655 7.10897 14.4103 6.51281 12.6924 6.51281C10.9744 6.51281 9.51929 7.10897 8.32697 8.30128C7.13469 9.49359 6.53854 10.9487 6.53854 12.6667C6.53854 14.3846 7.13469 15.8398 8.32697 17.0321C9.51929 18.2244 10.9744 18.8205 12.6924 18.8205ZM11.6924 16.2564V13.6666H9.10267V11.6667H11.6924V9.07698H13.6924V11.6667H16.2821V13.6666H13.6924V16.2564H11.6924Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </button>

                  )}
                  {zoomId > minZoom && (
                    
                    <button
                    title="Zoom Out"
                    onClick={handleZoomOutDeclaration}
                    className="btn p_v_b_r_btn"
                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3_354"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={32}
                        height={32}
                      >
                        <rect width={32} height={32} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3_354)">
                        <path
                          d="M26.0565 27.4359L17.6821 19.0615C17.0154 19.6119 16.2488 20.0427 15.3821 20.3538C14.5154 20.6649 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0314 6.90631 18.4533C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46157 6.90571 6.88208C8.4838 5.30261 10.412 4.51288 12.6903 4.51288C14.9686 4.51288 16.8975 5.30212 18.477 6.88061C20.0565 8.4591 20.8462 10.3878 20.8462 12.6667C20.8462 13.6188 20.6864 14.5282 20.3667 15.3949C20.047 16.2615 19.6205 17.0154 19.0872 17.6564L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8205C14.4103 18.8205 15.8655 18.2244 17.0578 17.0321C18.2501 15.8398 18.8462 14.3846 18.8462 12.6667C18.8462 10.9487 18.2501 9.49359 17.0578 8.30128C15.8655 7.10897 14.4103 6.51281 12.6924 6.51281C10.9744 6.51281 9.51929 7.10897 8.32697 8.30128C7.13469 9.49359 6.53854 10.9487 6.53854 12.6667C6.53854 14.3846 7.13469 15.8398 8.32697 17.0321C9.51929 18.2244 10.9744 18.8205 12.6924 18.8205ZM9.51291 13.6666V11.6667H15.8719V13.6666H9.51291Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </button>
                  )}
                </div>

                
                <div className="p_v_b_right">
                  {zoomId < maxZoom && (
                    <button
                    title="Zoom In"
                    onClick={handleZoomInId}
                    className="btn p_v_b_r_btn"
                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3_348"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={32}
                        height={32}
                      >
                        <rect width={32} height={32} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3_348)">
                        <path
                          d="M26.0565 27.4359L17.6821 19.0615C17.0154 19.6119 16.2488 20.0427 15.3821 20.3538C14.5154 20.6649 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0314 6.90631 18.4533C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46157 6.90571 6.88208C8.4838 5.30261 10.412 4.51288 12.6903 4.51288C14.9686 4.51288 16.8975 5.30212 18.477 6.88061C20.0565 8.4591 20.8462 10.3878 20.8462 12.6667C20.8462 13.6188 20.6864 14.5282 20.3667 15.3949C20.047 16.2615 19.6205 17.0154 19.0872 17.6564L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8205C14.4103 18.8205 15.8655 18.2244 17.0578 17.0321C18.2501 15.8398 18.8462 14.3846 18.8462 12.6667C18.8462 10.9487 18.2501 9.49359 17.0578 8.30128C15.8655 7.10897 14.4103 6.51281 12.6924 6.51281C10.9744 6.51281 9.51929 7.10897 8.32697 8.30128C7.13469 9.49359 6.53854 10.9487 6.53854 12.6667C6.53854 14.3846 7.13469 15.8398 8.32697 17.0321C9.51929 18.2244 10.9744 18.8205 12.6924 18.8205ZM11.6924 16.2564V13.6666H9.10267V11.6667H11.6924V9.07698H13.6924V11.6667H16.2821V13.6666H13.6924V16.2564H11.6924Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </button>

                  )}
                  {zoomId > minZoom && (
                    
                    <button
                    title="Zoom Out"
                    onClick={handleZoomOutId}
                    className="btn p_v_b_r_btn"
                  >
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3_354"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={32}
                        height={32}
                      >
                        <rect width={32} height={32} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3_354)">
                        <path
                          d="M26.0565 27.4359L17.6821 19.0615C17.0154 19.6119 16.2488 20.0427 15.3821 20.3538C14.5154 20.6649 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0314 6.90631 18.4533C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46157 6.90571 6.88208C8.4838 5.30261 10.412 4.51288 12.6903 4.51288C14.9686 4.51288 16.8975 5.30212 18.477 6.88061C20.0565 8.4591 20.8462 10.3878 20.8462 12.6667C20.8462 13.6188 20.6864 14.5282 20.3667 15.3949C20.047 16.2615 19.6205 17.0154 19.0872 17.6564L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8205C14.4103 18.8205 15.8655 18.2244 17.0578 17.0321C18.2501 15.8398 18.8462 14.3846 18.8462 12.6667C18.8462 10.9487 18.2501 9.49359 17.0578 8.30128C15.8655 7.10897 14.4103 6.51281 12.6924 6.51281C10.9744 6.51281 9.51929 7.10897 8.32697 8.30128C7.13469 9.49359 6.53854 10.9487 6.53854 12.6667C6.53854 14.3846 7.13469 15.8398 8.32697 17.0321C9.51929 18.2244 10.9744 18.8205 12.6924 18.8205ZM9.51291 13.6666V11.6667H15.8719V13.6666H9.51291Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </button>
                  )}
                </div>
                </div>

              <div style={{
                display: "flex",
                justifyContent: "space-around"
              }}>
              </div>

            </div>

            <div className="col-lg-4 pe-0 imagesAuditCol3">
              <div className="detailRightMainBox">

                
              <div className="d_r_box_1">
                  <p>
                    Id Card Name : <span>{customerInfo?.customer_id_name}</span>
                  </p>
                  <div className="d_r_box_1_sub_box">
                    <p>
                     Id Card Address : <span>{customerInfo?.customer_id_address}</span>
                    </p>
                  </div>
                </div>
                <hr className="imageAuditHr" />






                <div className="d_r_box_1">
                  <p>
                    Mobile : <span>{customerInfo?.telephone_number}</span>
                  </p>
                  <p>
                    Name : <span>{customerInfo?.name_of_the_subscriber}</span>
                  </p>
                  <div className="d_r_box_1_sub_box">
                    <p>
                      Type : <span>{customerInfo?.address_type}</span>
                    </p>
                    <p className="actDtPara">
                      Act.Dt : <span>{customerInfo?.sim_activation_date}</span>
                    </p>
                  </div>
                  <div className="d_r_box_1_sub_box">
                    <p className="d_r_box_1_input_para">
                      Color :{" "}
                      <span className="d-flex justify-content-center  align-items-center ">
                        <input
                          type="checkbox"
                          checked={color}
                          onChange={handleColorChange}
                          className="detail_d_r_box_1_checkBoxInput"
                        />
                      </span>
                    </p>
                    <p className="d_r_box_1_input_para">
                      CAF No :{" "}
                      <span>
                        {" "}
                        <input
                          type="text"
                          value={cafNo}
                          // value={customerInfo?.caf_serial_no}
                          className="form-control detail_d_r_box_1_input"
                          onChange={(e) => setCafNo(e.target.value)}
                        />
                      </span>
                    </p>
                  </div>
                  {/*  <p className="d_r_box_1_input_para">
                    Rv :{" "}
                    <span className="d-flex justify-content-center align-items-center ">
                      <input
                        type="checkbox"
                        checked={rv}
                        onChange={handleRvChange}
                        className="detail_d_r_box_1_checkBoxInput"
                      />
                    </span>
                  </p> */}
                  {/* <div className="d_r_box_1_sub_box">
                    <p className="actDtPara">
                      Act.Dt : <span>{customerInfo?.sim_activation_date}</span>
                    </p>
                  </div> */}
                  <div className="d_r_box_1_sub_box">
                    <p>
                      Address : <span>{customerInfo?.local_address}</span>
                    </p>
                  </div>
                </div>
                <hr className="imageAuditHr" />
                <div className="d_r_remark_box">
                  {/* remark checkbox with values */}
                  <p className="d_r_remark_title">Remark:</p>
                  <div className="d_r_remark_subBox">
                    {remarkList.length > 0 ? (
                      remarkList.map((ele, index) => {
                        return (
                          <div key={ele.id} className="remark_input_box">
                            <input
                              type="checkbox"
                              checked={remarkChecked[ele.name]}
                              onChange={() =>
                                handleRemarkCheckboxToggle(ele.name)
                              }
                              onKeyDown={(event) => handleKeyDown(event, index)}
                              onKeyUp={(event) => handleKeyUp(event, index)}
                              ref={(ref) => (remarkRefs.current[index] = ref)}
                            />
                            <label
                              onClick={() =>
                                handleRemarkCheckboxToggle(ele.name)
                              }
                              onKeyDown={(event) => handleKeyDown(event, index)}
                              onKeyUp={(event) => handleKeyUp(event, index)}
                              ref={(ref) => (remarkRefs.current[index] = ref)}
                              tabIndex="0"
                            >
                              {ele.name}
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <div className="remark_input_box">
                        <label>No Remark</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="selectedRemarkBox">
                  <p>Selected Remark:</p>
                  <textarea
                    type="text"
                    value={selectedRemark}
                    onChange={(e) => setSelectedRemark(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="selectedRemarkBox">
                  <p>Other Remark:</p>
                  <input
                    type="text"
                    value={otherRemark}
                    onChange={(e) => setOtherRemark(e.target.value)}
                    className="form-control"
                  />
                </div>
                {showBtn && (
                  <div className="imageAuditAcceptRejectBtnBox">
                    {!selectedRemark && (
                      <button
                        className="btn acceptBtn"
                        onClick={() => updateCustomerData("ACCEPTED")}
                      >
                        Accept
                      </button>
                    )}
                    {selectedRemark && (
                      <button
                        className="btn rejectBtn"
                        onClick={() => updateCustomerData("REJECTED")}
                      >
                        Reject
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
        {auditMessagePopUp && (
          <AuditModal auditMessagePopUp={auditMessagePopUp} />
        )}
      </section>
    </>
  );
};

export default DetailPage;
