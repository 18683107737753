import { useEffect } from "react";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import "./main.css";

const Main = () => {
  const cardInfo = [
    {
      id: "0",
      name: "Pune",
      //   simType: "PUNE",
      img: require("../../assets/images/ekyc.png"),
    },
    {
      id: "1",
      name: "ROM",
      //   simType: "ROM",
      img: require("../../assets/images/ekyc.png"),
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("info") === null) {
      navigate("/login");
    }
  }, []);

  const handleImageAuditPage = (name) => {
    navigate(`/home/${name}`);
  };
  return (
    <>
      <Header />
      <section className="home-main">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            {cardInfo.map((ele) => {
              return (
                <div key={ele.id} className="col-lg-3">
                  <div
                    className="dashboardCard"
                    onClick={() => handleImageAuditPage(ele.name)}
                  >
                    <div className="MainCardIconBox">
                      <p>{ele.name}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
