import Header from "../../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import "./adminHome.css";
import { useEffect, useState } from "react";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import SideBar from "../../components/sideBar/SideBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminHome = () => {
  const navigate = useNavigate();
  const [city, setCity] = useState("");
  const [base64Data, setBase64Data] = useState("");
  const [uploadFileData, setUploadFileData] = useState("");
  const [downloadSampleFileUrl, setDownloadSampleFileUrl] = useState("");
  const userDetails = sessionStorage.getItem("adminInfo");
  const userInfo = JSON.parse(userDetails);

  useEffect(() => {
    if (sessionStorage.getItem("adminInfo") === null) {
      navigate("/admin/login");
    }
  }, []);

  // download sample file

  const handleDownloadSampleFile = async () => {
    try {
      const finalData = {};
      const response = await axios.post(
        API_BASE_URL + "admin/excel_sample_file",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      // console.log("download sample file response", response.data.data);
      setDownloadSampleFileUrl(response.data.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleDownloadSampleFile();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Content = e.target.result.split(",")[1];
        setBase64Data(base64Content);
      };

      reader.readAsDataURL(file);
    }
  };

  // upload file api

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (city === "") {
      toast.error("Select City");
      return;
    }
    try {
      if (base64Data) {
        const data = {
          user_id: userInfo.id,
          excel_file: base64Data,
          city: city,
        };
        console.log("data", data);
        const response = await axios.post(
          API_BASE_URL + "admin/upload_bulk_customer",
          data,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        console.log("response data", response.data);

        setUploadFileData(response.data);
        alert(response.data.message);
      } else {
        console.log("Please select an Excel file before uploading.");
      }
    } catch (error) {
      console.log("error occurred in add imp places", error);
    }
  };

  return (
    <>
      <Header />
      <section className="adminHomeMain">
        <SideBar />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="downloadSamplePdfFileBox">
                <h5>Import File</h5>

                <Link
                  to={downloadSampleFileUrl}
                  className="btn"
                  target="_blank"
                  download
                >
                  Download Sample File
                </Link>
              </div>
            </div>
            <div className="col-lg-12 ">
              <div className="pdfUploadMain">
                <div className="uploadFileSelectOption">
                  <select
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="form-control"
                  >
                    <option>Select City</option>
                    <option value="Pune">Pune</option>
                    <option value="ROM">ROM</option>
                  </select>
                </div>
                <form
                  id="importForm"
                  name="importForm"
                  className="pdfUploadForm"
                >
                  <input
                    type="file"
                    accept=".xls, .xlsx"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  <button
                    className="btn"
                    onClick={handleUploadFile}
                    disabled={!base64Data}
                  >
                    Upload
                  </button>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      </section>
    </>
  );
};

export default AdminHome;
