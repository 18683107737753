import Header from "../../components/header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import SideBar from "../../components/sideBar/SideBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../manageAuditor/auditor.css";

const EditQc = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [formData, setFormData] = useState({
    userId: "",
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const userDetails = sessionStorage.getItem("adminInfo");
  const userInfo = JSON.parse(userDetails);

  useEffect(() => {
    if (sessionStorage.getItem("adminInfo") === null) {
      navigate("/admin/login");
    }
  }, []);

  //   details api

  useEffect(() => {
    handleEditorDetails();
  }, [id]);

  const handleEditorDetails = async () => {
    const formData = {
      qc_id: id,
    };
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/qc_details",
        formData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("qc details response", response.data);
      if (response.data.status) {
        setFormData({
          userId: response.data.data.user_id,
          name: response.data.data.name,
          email: response.data.data.email,
          mobile: response.data.data.mobile,
          password: response.data.data.password,
        });
      }
      if (response.data.data.status === "Y") {
        setStatus(!status);
      } else {
        setStatus(status);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  const validation = () => {
    let valid = true;
    if (formData.userId === "") {
      toast.error("User Id must be require !");
      valid = false;
    } else if (formData.name === "") {
      toast.error("Name must be require !");
      valid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      toast.error("Name should only contain letters");
      valid = false;
    } else if (formData.email === "") {
      toast.error("Email address must be require");
      valid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      toast.error("Mobile no. must be a 10-digit number");
      valid = false;
    } else if (formData.password === "") {
      toast.error("Password must be require");
      valid = false;
    }
    return valid;
  };

  // edit auditor api

  const handleEditAuditor = async (event) => {
    event.preventDefault();
    const { userId, name, email, mobile, password } = formData;
    if (!validation()) {
      return;
    }
    const finalData = {
      qc_id: id,
      user_id: userId,
      name: name,
      email: email,
      mobile: mobile,
      password: password,
      status: status ? "Y" : "N",
    };
    // console.log("update qc finalData", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "admin/update_qc",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("update qc response", response.data);
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/admin/manage-qc");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Header />
      <section className="auditorMain">
        <SideBar />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="auditorDataTableBox">
                <div className="auditorTitleBox">
                  <h5>Edit QC User</h5>
                </div>
                <div className="auditorMainFormSec">
                  <form
                    className="addAuditorForm"
                    name="addAuditor"
                    id="addAuditor"
                    onSubmit={handleEditAuditor}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="auditorGroup">
                          <label htmlFor="">User Id :-</label>
                          <input
                            type="text"
                            name="userId"
                            value={formData.userId}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="auditorGroup">
                          <label htmlFor="">Name :-</label>
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="auditorGroup">
                          <label htmlFor="">Email :-</label>
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="auditorGroup">
                          <label htmlFor="">Mobile :-</label>
                          <input
                            type="text"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="auditorGroup">
                          <label htmlFor="">Password :-</label>
                          <input
                            type="text"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="auditorGroup">
                          <label htmlFor="">Status :-</label>
                          <input
                            type="checkbox"
                            checked={status}
                            onChange={handleStatusChange}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="auditorBtnGroup">
                          <button className="btn">Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
      </section>
    </>
  );
};

export default EditQc;
