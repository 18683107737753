import React from "react";
import "./auditModal.css";
import { useNavigate } from "react-router-dom";

const AuditModal = ({ auditMessagePopUp }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="auditOverLay">
      <div className="auditMessageBox">
        <p>{auditMessagePopUp}</p>
        <button className="btn" onClick={handleBack}>
          Back
        </button>
      </div>
    </div>
  );
};

export default AuditModal;
