/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import "../detailPage/detailPage.css";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { json, useNavigate, useParams } from "react-router-dom";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import AuditModal from "../../components/auditModal/AuditModal";
import Loader from "../../components/loader/Loader";
import { LuRotateCw } from "react-icons/lu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoHome } from "react-icons/io5";

pdfjs.GlobalWorkerOptions.workerSrc =
  "path-to-pdfjs/2.9.359/build/pdf.worker.min.js";

const QcPanelDetails = () => {
  const remarkRefs = useRef([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cafNo, setCafNo] = useState("");
  const [auditMessagePopUp, setAuditMessagePopUp] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [remarkChecked, setRemarkChecked] = useState({});
  const [customerInfo, setCustomerInfo] = useState([]);
  const [color, setColor] = useState(false);
  const [rv, setRv] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState("");
  const [otherRemark, setOtherRemark] = useState("");
  const [checkedValues, setCheckedValue] = useState([]);
  var isChecked = "";
  const handleColorChange = () => {
    setColor(!color);
  };

  useEffect(() => {
    if (sessionStorage.getItem("qcInfo") === null) {
      navigate("/qc-panel/login");
    }
  }, []);

  const handleRvChange = () => {
    setRv(!rv);
  };

  const userDetails = sessionStorage.getItem("qcInfo");
  const userInfo = JSON.parse(userDetails);

  const handleRemarkCheckboxToggle = (label) => {
    isChecked = checkedValues.includes(label); // Check if label is in checkedValues
    const updatedCheckedValues = isChecked
      ? checkedValues.filter((value) => value !== label) // Remove label if it exists
      : [...checkedValues, label]; // Add label if it doesn't exist
    setCheckedValue(updatedCheckedValues); // Update checkedValues state
    setRemarkChecked({
      ...remarkChecked,
      [label]: !remarkChecked[label],
    });
  };

  // handle keys

  const handleKeyDown = (event, index) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = (index + 1) % remarkRefs.current.length;
      remarkRefs.current[nextIndex].focus();
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleRemarkCheckboxToggle(remarkList[index].name);
    } else if (event.key === " ") {
      event.preventDefault();
      handleRemarkCheckboxToggle(remarkList[index].name);
    }
  };

  const handleKeyUp = (event, index) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      const prevIndex = index === 0 ? remarkRefs.current.length - 1 : index - 1;
      remarkRefs.current[prevIndex].focus();
    }
    // else if (event.key === "Enter") {
    //   event.preventDefault();
    //   handleRemarkCheckboxToggle(remarkList[index].name);
    // }
  };

  // pass remark value in select remark input

  useEffect(() => {
    const initialRemarkChecked = {};
    Object.entries(checkedValues).forEach(([id, name]) => {
      initialRemarkChecked[name] = true; // Assuming all initial values are checked
    });
    setRemarkChecked(initialRemarkChecked);
    // const checkedRemarks = Object.entries(initialRemarkChecked)
    //   .filter(([id, isChecked]) => isChecked)
    //   .map(([id]) => checkedValues[id]); // Use names instead of IDs
    setSelectedRemark(checkedValues.join(", "));
  }, [checkedValues]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1.0);
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    // Specify the path to the PDF.js worker script
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  // document load

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    if (pageNumber > numPages) {
      setPageNumber(numPages);
    }
  };

  // prev page btn

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) =>
      prevPageNumber > 1 ? prevPageNumber - 1 : prevPageNumber
    );
    setZoom(1.0);
    setRotate(0);
  };

  // next page btn
  const goToNextPage = () => {
    setPageNumber((prevPageNumber) =>
      prevPageNumber < numPages ? prevPageNumber + 1 : prevPageNumber
    );
    setZoom(1.0);
    setRotate(0);
  };

  // zoom in btn

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };

  // zoom out btn

  const handleZoomOut = () => {
    if (zoom > 0.2) {
      setZoom(zoom - 0.1);
    }
  };

  // reset btn

  const handleResetZoom = () => {
    setZoom(1.0);
    setPageNumber(1);
    setRotate(0);
  };
  // Rotate page btn

  const handleRotatePage = () => {
    setRotate((prevRotate) => (prevRotate + 90) % 360);
  };

  // customer data api

  const handleCustomerInfo = async () => {
    setIsLoading(true);
    try {
      const finalData = {
        // sim_type: simType,
        customer_id: id,
      };
      // console.log("finalData customer info", finalData);
      const response = await axios.post(
        API_BASE_URL + "auditor/customer_form_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      // console.log("response customer info", response.data);
      if (response.data.status === true) {
        setCustomerInfo(response.data.data);
        setIsLoading(false);
        setCafNo(response.data.data.caf_serial_no);
        // other remark
        if (
          response.data.data.qc_other_remark === null ||
          response.data.data.qc_other_remark === ""
        ) {
          setOtherRemark(response.data.data.other_remark);
        } else {
          setOtherRemark(response.data.data.qc_other_remark);
        }
        // qc remark remark
        if (
          response.data.data.qc_remark === null ||
          response.data.data.qc_remark === ""
        ) {
          const checkedValue = JSON.parse(response.data.data.remark);
          setCheckedValue(checkedValue);
          setRemarkChecked(checkedValue);
        } else {
          const checkedValue = JSON.parse(response.data.data.qc_remark);
          setCheckedValue(checkedValue);
          setRemarkChecked(checkedValue);
        }

        if (
          response.data.data.scanned_photograph_coloured_of_the_subscriber ===
          "YES"
        ) {
          setColor(!color);
        } else {
          setColor(color);
        }
      } else {
        setAuditMessagePopUp(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  useEffect(() => {
    handleCustomerInfo();
  }, [id]);

  // for customer info data call again

  const handleRefreshCustomerInfo = async () => {
    await handleCustomerInfo();
  };

  // close status api

  // const handleOpenedCustomer = async () => {
  //   try {
  //     const finalData = {
  //       user_id: userInfo.id,
  //       customer_id: customerInfo?.id,
  //       status: "close",
  //     };
  //     console.log("opened", finalData);
  //     const response = await axios.post(
  //       API_BASE_URL + "auditor/update_form_status",
  //       finalData,
  //       {
  //         auth: {
  //           username: API_AUTH_USERNAME,
  //           password: API_AUTH_PASSWORD,
  //         },
  //       }
  //     );
  //     console.log("response opened customer", response.data);
  //   } catch (error) {
  //     console.log(error.response?.data.error);
  //   }
  // };

  // useEffect(() => {
  //   const handleWindowClose = () => {
  //     handleOpenedCustomer();
  //   };

  //   const handleUnload = () => {
  //     handleOpenedCustomer();
  //   };

  //   window.addEventListener("beforeunload", handleWindowClose);
  //   window.addEventListener("unload", handleUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleWindowClose);
  //     window.removeEventListener("unload", handleUnload);
  //   };
  // }, [userInfo?.id, customerInfo?.id]);

  // useEffect(() => {
  //   return () => {
  //     if (window.location.pathname === "/") {
  //       handleOpenedCustomer();
  //     }
  //   };
  // }, [window.location.pathname, userInfo?.id, customerInfo?.id]);

  // update customer data api
  const updateCustomerData = async (status) => {
    // var check = window.confirm(`Are You Sure To ${status} This Data ?`);
    // if (!check) {
    //   return;
    // }
    setIsLoading(true);
    try {
      // remark value
      const checkedRemarks = Object.entries(remarkChecked)
        .filter(([label, isChecked]) => isChecked)
        .map(([label]) => label);

      const finalData = {
        user_id: userInfo.id,
        customer_id: customerInfo?.id,
        color: color ? "YES" : "NO",
        // rv: rv ? "YES" : "NO",
        caf_no: cafNo,
        remark: checkedRemarks,
        selected_remark: selectedRemark,
        other_remark: otherRemark,
        status: status,
      };
      console.log("finalData update customer ", finalData);
      const response = await axios.post(
        API_BASE_URL + "auditor/update_form_status_by_qc",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("response update customer", response.data);
      if (response.data.status === true) {
        handleRefreshCustomerInfo();
        toast.success(response.data.message);
        setColor(false);
        setRv(false);
        setSelectedRemark("");
        setOtherRemark("");
        setRemarkChecked([]);
        setIsLoading(false);
        // handleResetZoom();
        navigate("/qc-panel");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  // customer data api

  const handleRemarkList = async () => {
    try {
      const response = await axios.get(API_BASE_URL + "auditor/remark_list", {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      // console.log("response remark list", response.data);
      setRemarkList(response.data.data);
    } catch (error) {
      console.log(error.response?.data.error);
    }
  };

  useEffect(() => {
    handleRemarkList();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      {/* {isLoading ? (
        <Loader />
      ) : (
       
      )} */}
      <section className="detailMain">
        {isLoading && <Loader />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 ps-0  pe-0 ">
              <div className="imageAuditMain">
                <div className="homeNavigateBox" onClick={handleBack}>
                  <IoHome />
                </div>
                <p>Image Audit</p>
              </div>
            </div>
          </div>
          <div className="row p-2">
            <div className="col-lg-8 imagesAuditCol9 ps-0 pe-0">
              <div className="pdfViewerMain">
                <div className="pdfViewerTop">
                  {customerInfo?.customer_form_pdf_link === "" ||
                  customerInfo?.customer_form_pdf_link ===
                    "https://microdynamicsoftware.uk/vi_image_audit/uploads/pdf/" ? (
                    <p className="text-danger">No Images Available</p>
                  ) : (
                    <Document
                      file={customerInfo?.customer_form_pdf_link}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        pageNumber={pageNumber}
                        width={window.innerWidth >= 1500 ? 1300 : 950}
                        // width={900}
                        scale={zoom}
                        // style={{ width: "10%" }}
                        rotate={rotate}
                      />
                    </Document>
                  )}
                </div>
                <div className="pdfViewerBottom">
                  <div className="p_v_b_left">
                    <span>From</span>
                    <div className="box">1</div>
                    <span>To</span>
                    <div className="box">{pageNumber}</div>
                    <span>Total Pages</span>
                    <div className="box">{numPages || 0}</div>
                  </div>
                  <div className="p_v_b_right">
                    {/* zoom out */}
                    <button
                      title="Zoom Out"
                      onClick={handleZoomOut}
                      className="btn p_v_b_r_btn"
                    >
                      <svg
                        width={28}
                        height={28}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3_354"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={0}
                          width={32}
                          height={32}
                        >
                          <rect width={32} height={32} fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3_354)">
                          <path
                            d="M26.0565 27.4359L17.6821 19.0615C17.0154 19.6119 16.2488 20.0427 15.3821 20.3538C14.5154 20.6649 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0314 6.90631 18.4533C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46157 6.90571 6.88208C8.4838 5.30261 10.412 4.51288 12.6903 4.51288C14.9686 4.51288 16.8975 5.30212 18.477 6.88061C20.0565 8.4591 20.8462 10.3878 20.8462 12.6667C20.8462 13.6188 20.6864 14.5282 20.3667 15.3949C20.047 16.2615 19.6205 17.0154 19.0872 17.6564L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8205C14.4103 18.8205 15.8655 18.2244 17.0578 17.0321C18.2501 15.8398 18.8462 14.3846 18.8462 12.6667C18.8462 10.9487 18.2501 9.49359 17.0578 8.30128C15.8655 7.10897 14.4103 6.51281 12.6924 6.51281C10.9744 6.51281 9.51929 7.10897 8.32697 8.30128C7.13469 9.49359 6.53854 10.9487 6.53854 12.6667C6.53854 14.3846 7.13469 15.8398 8.32697 17.0321C9.51929 18.2244 10.9744 18.8205 12.6924 18.8205ZM9.51291 13.6666V11.6667H15.8719V13.6666H9.51291Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                    {/* zoom in */}
                    <button
                      title="Zoom In"
                      onClick={handleZoomIn}
                      className="btn p_v_b_r_btn"
                    >
                      <svg
                        width={28}
                        height={28}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3_348"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={0}
                          width={32}
                          height={32}
                        >
                          <rect width={32} height={32} fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3_348)">
                          <path
                            d="M26.0565 27.4359L17.6821 19.0615C17.0154 19.6119 16.2488 20.0427 15.3821 20.3538C14.5154 20.6649 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0314 6.90631 18.4533C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46157 6.90571 6.88208C8.4838 5.30261 10.412 4.51288 12.6903 4.51288C14.9686 4.51288 16.8975 5.30212 18.477 6.88061C20.0565 8.4591 20.8462 10.3878 20.8462 12.6667C20.8462 13.6188 20.6864 14.5282 20.3667 15.3949C20.047 16.2615 19.6205 17.0154 19.0872 17.6564L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8205C14.4103 18.8205 15.8655 18.2244 17.0578 17.0321C18.2501 15.8398 18.8462 14.3846 18.8462 12.6667C18.8462 10.9487 18.2501 9.49359 17.0578 8.30128C15.8655 7.10897 14.4103 6.51281 12.6924 6.51281C10.9744 6.51281 9.51929 7.10897 8.32697 8.30128C7.13469 9.49359 6.53854 10.9487 6.53854 12.6667C6.53854 14.3846 7.13469 15.8398 8.32697 17.0321C9.51929 18.2244 10.9744 18.8205 12.6924 18.8205ZM11.6924 16.2564V13.6666H9.10267V11.6667H11.6924V9.07698H13.6924V11.6667H16.2821V13.6666H13.6924V16.2564H11.6924Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                    {/* prev */}
                    <button
                      onClick={goToPreviousPage}
                      disabled={pageNumber <= 1}
                      className="btn p_v_b_r_btn"
                      title="Previous Page"
                    >
                      <svg
                        width={28}
                        height={28}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3_384"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={0}
                          width={32}
                          height={32}
                        >
                          <rect width={32} height={32} fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3_384)">
                          <path
                            d="M13.2694 23.5384L5.73096 16L13.2694 8.46155L14.6745 9.90768L9.58216 15H26.2694V16.9999H9.58216L14.6745 22.0923L13.2694 23.5384Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                    <div className="box">{pageNumber}</div>
                    {/* next */}
                    <button
                      onClick={goToNextPage}
                      disabled={pageNumber >= numPages}
                      className="btn p_v_b_r_btn"
                      title="Next Page"
                    >
                      <svg
                        width={28}
                        height={28}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3_378"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={0}
                          width={32}
                          height={32}
                        >
                          <rect width={32} height={32} fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3_378)">
                          <path
                            d="M18.4615 23.5384L17.0564 22.0923L22.1487 16.9999H6V15H22.1487L17.0564 9.90768L18.4615 8.46155L25.9999 16L18.4615 23.5384Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                    {/* reset */}
                    <button
                      onClick={handleResetZoom}
                      className="btn p_v_b_r_btn"
                      title="Reset"
                    >
                      <svg
                        width={28}
                        height={28}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3_390"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={0}
                          width={32}
                          height={32}
                        >
                          <rect width={32} height={32} fill="white" />
                        </mask>
                        <g mask="url(#mask0_3_390)">
                          <path
                            d="M6.88957 20.8359C6.48615 20.0855 6.18145 19.3107 5.97547 18.5116C5.76949 17.7124 5.6665 16.8974 5.6665 16.0667C5.6665 13.1829 6.66992 10.7287 8.67674 8.70389C10.6836 6.67911 13.1246 5.66672 15.9998 5.66672H17.0409L14.5742 3.20002L15.9793 1.79492L20.851 6.66669L15.9793 11.5385L14.5742 10.1334L17.0409 7.66666H15.9998C13.6836 7.66666 11.7156 8.48204 10.0959 10.1128C8.47627 11.7436 7.66644 13.7282 7.66644 16.0667C7.66644 16.6188 7.72669 17.1705 7.8472 17.7218C7.96771 18.2731 8.14848 18.8111 8.3895 19.3359L6.88957 20.8359ZM16.0203 30.2051L11.1486 25.3334L16.0203 20.4616L17.4254 21.8667L14.9587 24.3334H15.9998C18.316 24.3334 20.284 23.518 21.9037 21.8872C23.5233 20.2564 24.3332 18.2718 24.3332 15.9334C24.3332 15.3812 24.2729 14.8295 24.1524 14.2782C24.0319 13.727 23.8511 13.1889 23.6101 12.6641L25.11 11.1642C25.5135 11.9146 25.8182 12.6894 26.0241 13.4885C26.2301 14.2876 26.3331 15.1026 26.3331 15.9334C26.3331 18.8171 25.3297 21.2714 23.3229 23.2962C21.316 25.3209 18.875 26.3333 15.9998 26.3333H14.9587L17.4254 28.8L16.0203 30.2051Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                    {/* rotate */}
                    <button
                      onClick={handleRotatePage}
                      className="btn p_v_b_r_btn"
                      title="Rotate"
                    >
                      <LuRotateCw className="rotateIcon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pe-0 imagesAuditCol3">
              <div className="detailRightMainBox">
                <div className="d_r_box_1">
                  <p>
                    Mobile : <span>{customerInfo?.telephone_number}</span>
                  </p>
                  <p>
                    Name : <span>{customerInfo?.name_of_the_subscriber}</span>
                  </p>
                  <div className="d_r_box_1_sub_box">
                    <p>
                      Type : <span>{customerInfo?.address_type}</span>
                    </p>
                    <p className="actDtPara">
                      Act.Dt : <span>{customerInfo?.sim_activation_date}</span>
                    </p>
                  </div>
                  <div className="d_r_box_1_sub_box">
                    <p className="d_r_box_1_input_para">
                      Color :{" "}
                      <span className="d-flex justify-content-center  align-items-center ">
                        <input
                          type="checkbox"
                          checked={color}
                          onChange={handleColorChange}
                          className="detail_d_r_box_1_checkBoxInput"
                        />
                      </span>
                    </p>
                    <p className="d_r_box_1_input_para">
                      CAF No :{" "}
                      <span>
                        {" "}
                        <input
                          type="text"
                          value={cafNo}
                          className="form-control detail_d_r_box_1_input"
                          onChange={(e) => setCafNo(e.target.value)}
                          // readOnly
                        />
                      </span>
                    </p>
                  </div>
                  {/*  <p className="d_r_box_1_input_para">
                    Rv :{" "}
                    <span className="d-flex justify-content-center align-items-center ">
                      <input
                        type="checkbox"
                        checked={rv}
                        onChange={handleRvChange}
                        className="detail_d_r_box_1_checkBoxInput"
                      />
                    </span>
                  </p> */}
                  {/* <div className="d_r_box_1_sub_box">
                    <p className="actDtPara">
                      Act.Dt : <span>{customerInfo?.sim_activation_date}</span>
                    </p>
                  </div> */}
                  <div className="d_r_box_1_sub_box">
                    <p>
                      Address : <span>{customerInfo?.local_address}</span>
                    </p>
                  </div>
                </div>
                <hr className="imageAuditHr" />
                <div className="d_r_remark_box">
                  {/* remark checkbox with values */}
                  <p className="d_r_remark_title">Remark:</p>
                  <div className="d_r_remark_subBox">
                    {remarkList.length > 0 ? (
                      remarkList.map((ele, index) => {
                        return (
                          <div key={ele.id} className="remark_input_box">
                            <input
                              type="checkbox"
                              // checked={
                              //   remarkList.some((value) =>
                              //     checkedValues.includes(value)
                              //   )
                              //     ? "checked"
                              //     : remarkChecked[ele.name]
                              // }
                              checked={checkedValues.includes(ele.name)}
                              onChange={() =>
                                handleRemarkCheckboxToggle(ele.name)
                              }
                              onKeyDown={(event) => handleKeyDown(event, index)}
                              onKeyUp={(event) => handleKeyUp(event, index)}
                              ref={(ref) => (remarkRefs.current[index] = ref)}
                            />
                            <label
                              onClick={() =>
                                handleRemarkCheckboxToggle(ele.name)
                              }
                              onKeyDown={(event) => handleKeyDown(event, index)}
                              onKeyUp={(event) => handleKeyUp(event, index)}
                              ref={(ref) => (remarkRefs.current[index] = ref)}
                              tabIndex="0"
                            >
                              {ele.name}
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <div className="remark_input_box">
                        <label>No Remark</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="selectedRemarkBox">
                  <p>Selected Remark:</p>
                  <textarea
                    type="text"
                    value={selectedRemark}
                    onChange={(e) => setSelectedRemark(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="selectedRemarkBox">
                  <p>Other Remark:</p>
                  <input
                    type="text"
                    value={otherRemark}
                    onChange={(e) => setOtherRemark(e.target.value)}
                    className="form-control"
                  />
                </div>

                <div className="imageAuditAcceptRejectBtnBox">
                  {!selectedRemark && (
                    <button
                      className="btn acceptBtn"
                      onClick={() => updateCustomerData("ACCEPTED")}
                    >
                      Accept
                    </button>
                  )}
                  {selectedRemark && (
                    <button
                      className="btn rejectBtn"
                      onClick={() => updateCustomerData("REJECTED")}
                    >
                      Reject
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position={"top-right"}
            autoClose={2000}
            pauseOnHover={false}
            closeOnClick={true}
          />
        </div>
        {auditMessagePopUp && (
          <AuditModal auditMessagePopUp={auditMessagePopUp} />
        )}
      </section>
    </>
  );
};

export default QcPanelDetails;
