import React from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "./sideBar.css";

const SideBar = () => {
  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 bg-light"
      style={{ width: 280 }}
    >
      <Link
        to="/admin/home"
        className="d-flex align-items-center justify-content-center  link-dark text-decoration-none"
      >
        <img src={logo} width={100} alt="" />
      </Link>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li
          className={
            window.location.pathname === "/admin/home"
              ? "sidebarNav nav-item"
              : "nav-item"
          }
        >
          <Link
            to="/admin/home"
            className="nav-link sidebarLinks"
            aria-current="page"
          >
            Import File (Upload Excel)
          </Link>
        </li>
        <li
          className={
            window.location.pathname === "/admin/export-file"
              ? "sidebarNav nav-item"
              : "nav-item"
          }
        >
          <Link to="/admin/export-file" className="nav-link sidebarLinks">
            Export File (Download Excel)
          </Link>
        </li>
        <li
          className={
            window.location.pathname === "/admin/manage-auditor"
              ? "sidebarNav nav-item"
              : "nav-item"
          }
        >
          <Link to="/admin/manage-auditor" className="nav-link sidebarLinks">
            Manage Auditor
          </Link>
        </li>
        <li
          className={
            window.location.pathname === "/admin/manage-qc"
              ? "sidebarNav nav-item"
              : "nav-item"
          }
        >
          <Link to="/admin/manage-qc" className="nav-link sidebarLinks">
            Manage QC User
          </Link>
        </li>
        <li
          className={
            window.location.pathname === "/admin/manage-remark"
              ? "sidebarNav nav-item"
              : "nav-item"
          }
        >
          <Link to="/admin/manage-remark" className="nav-link sidebarLinks">
            Manage Remark
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
