import "./footer.css";

const Footer = () => {
  return (
    <section className="footer-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-box">
              <p>developed and designed by microdynamic software pvt.ltd</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
